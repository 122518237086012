<template>
  <v-container fluid>
    <form @submit.prevent="handleSubmit()">
      <v-row>
        <v-col cols="3">
          <v-row>
            <v-col>
              <p class="text-sm font-bold mb-4 black--text">
                {{
                  isMarketingEdit ? `Edit ${currentTab}` : `Add ${currentTab}`
                }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-row>
            <v-col cols="12" class="pl-[20px]">
              <v-row class="mb-5">
                <v-col cols="12">
                  <div>Theme <span class="red--text">*</span></div>
                  <v-text-field
                    dense
                    outlined
                    required
                    :disabled="true"
                    v-model="mkt_theme"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div>Image URL<span class="red--text">*</span></div>
                  <v-file-input
                    :show-size="true"
                    class="fileInput"
                    max-width="450px"
                    autofocus
                    id="file"
                    type="file"
                    placeholder="Select file (..jpg or .svg or .png File only)"
                    ref="bannerFile"
                    :multiple="false"
                    v-model="mkt_img_text"
                    accept="image/png, image/jpeg"
                    @change="listen($event)"
                    outlined
                    dense
                    append-icon="mdi-folder-open"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
                <!-- <v-col cols="6">
                      <div>Button Name <span class="red--text">*</span></div>
                      <v-text-field dense outlined required v-model="mkt_button_name"   ></v-text-field>
  
                     
                    </v-col>
                    <v-col cols="6">
                      <div>Button Color <span class="red--text">*</span></div>
                      <v-text-field dense outlined required v-model="mkt_button_color"   ></v-text-field>
  
                     
                    </v-col> -->
                <!-- <v-col cols="6">
                      <div>Button URL <span class="red--text">*</span></div>
                      <v-text-field dense outlined required v-model="mkt_button_url"   ></v-text-field>
  
                      
                    </v-col>
                    <v-col cols="6">
                      <div>Card Color <span class="red--text">*</span></div>
                      <v-text-field dense outlined required v-model="mkt_card_color"   ></v-text-field>
  
                     
                    </v-col> -->
                <!-- <v-col cols="6">
                    <div>Theme<span class="red--text">*</span></div>
                    <v-text-field
                      dense
                      outlined
                      required
                      :disabled="true"
                      v-model="mkt_theme"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <div>Image URL <span class="red--text">*</span></div>
                    <v-text-field
                      dense
                      outlined
                      required
                      v-model="mkt_img_url"
                    ></v-text-field>
                  </v-col> -->
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-col cols="1">
          <v-btn color="grey" dark @click="goBack()">Cancel</v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            type="submit"
            :loading="materialLoader"
            :disabled="materialLoader"
            height="40"
            width="96"
            @click="handleSubmit()"
            depressed
            color="primary"
            class="mr-3 text-capitalize"
            >Submit</v-btn
          >
          <!-- <v-btn
            @click="handleSubmit()"
            color="blue"
            dark
            :loading="materialLoader"
            :disabled="materialLoader"
          >
            Submit
          </v-btn> -->
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "addOrDeleteMaterials",
  props: {
    currentTab: {
      type: String,
    },
  },
  data: () => ({
    mkt_button_color: "",
    mkt_button_name: "",
    mkt_pageLocation: "",
    mkt_tag: "",
    submitted: false,
    currentData: [],
    mkt_button_url: "",
    mkt_img_text: null,
    mkt_img_url: "",
    isDropdown: false,
    mkt_card_color: "",
    mkt_position: "",
    currentId: "",
    mkt_theme: "",
  }),
  methods: {
    handleSubmit() {
      this.submitted = true;
      if (this.valid()) {
        let json = {
          pageLocation: this.mkt_pageLocation,
          tag: this.mkt_tag,
          source: "WEB",
          position: this.mkt_position,
          imageUrl: this.mkt_img_url,
        };
        if (this.isMarketingEdit) {
          json.id = this.currentId.toString();
          this.$store
            .dispatch("uploadFile/updateMarketingMaterial", json)
            .finally(() => {
              this.mkt_button_url = "";
              this.mkt_img_text = null;
            });
        } else {
          this.$store.dispatch("uploadFile/insertMarketingMaterial", json);
        }
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Please Upload an image to continue",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },
    async listen(event) {
      if (event) {
        const file = event;

        const convertBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
              resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        };

        this.mkt_img_url = await convertBase64(file);
      } else {
        this.mkt_img_url = "";
      }
    },
    valid() {
      if (
        this.mkt_position != "" &&
        this.mkt_img_url != "" &&
        this.mkt_pageLocation != "" &&
        this.mkt_tag != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    goBack() {
      this.$store.commit("uploadFile/openInsertImageInput", false);
    },
  },
  created() {
    if (this.isMarketingEdit) {
      this.currentId = this.marketingEditData.id;
      this.mkt_position = this.marketingEditData.position;
      this.mkt_theme = this.marketingEditData.theme;
      this.mkt_pageLocation = this.marketingEditData.pageLocation;
      this.mkt_tag = this.marketingEditData.tag;

      //  this.mkt_img_url = this.marketingEditData.imageUrl;
    }
  },
  beforeDestroy() {
    this.$store.commit("uploadFile/setIsMarketingEdit", false);
  },
  computed: {
    ...mapState("uploadFile", [
      "marketingEditData",
      "isMarketingEdit",
      "materialLoader",
    ]),
  },
};
</script>

<style scoped>
.primaryColor {
  color: blue; /* Change color to your desired primary color */
}
</style>
